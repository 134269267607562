import React, { useState } from 'react';
import RPButton from '@/components/RPButton';
import FilterComponent from '@/components/Filter';
import CardComponent from '@/components/CardComponent';
import TableComponent from '@/components/TableComponent';
import {
  Space,
} from 'antd';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import transactionsIcon from '@/assets/images/transactions/transactions-icon.svg';
import { TransactionService } from '@/services';
import { parseTransactionsToDataTable, optsStatusTransaction } from '@/utils/transactions';
import './table.scss';

const renderLink = (text) => (
  <Link
    className="text-link"
    to={`${ROUTES.APP.TRANSACTION_PATH}/${text}`}
  >
    {text}
  </Link>
);
const renderStatus = (text) => {
  let status = '';
  switch (text) {
    case 'FAILED':
      status = 'failed';
      break;
    case 'CAPTURED':
      status = 'active';
      break;
    case 'PAYOUT':
      status = 'payout';
      break;
    case 'PAID':
      status = 'paid';
      break;
    default:
      status = 'pending';
      break;
  }
  return (<span className={`text-status-${status}`}>{capitalize(text)}</span>);
};
const columns = [
  {
    title: 'Transaction ID',
    dataIndex: 'id',
    key: 'id',
    render: renderLink,
    fixed: 'left',
  },
  {
    title: 'Order ID',
    dataIndex: 'orderId',
    key: 'orderId',
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
    width: 130,
    align: 'center',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    ellipsis: true,
  },
  {
    title: 'Contact',
    key: 'contact',
    dataIndex: 'contact',
    width: 150
  },
  {
    title: 'Created On',
    key: 'createdOn',
    dataIndex: 'createdOn',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: renderStatus,
    align: 'right',
    width: 135,
    fixed: 'right',
  },
];

function TransactionsTable({
  refType,
  refId
}) {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPage] = useState(10);

  const handleOnClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const initData = async (params) => {
    setLoading(true);
    if (refType) {
      params.refType = refType;
    }

    if (refType && refId) {
      params.refId = refId;
    }

    const res = await TransactionService.getTransactions(params);
    if (res.data && res.data.length > 0) {
      const transactions = parseTransactionsToDataTable(res.data);
      setData([...transactions]);
    } else {
      setData([]);
    }
    setTotalPage(res?.pagination?.totalPages || 1);
    setLoading(false);
  };

  const exportCSV = async () => {
    if (refType) {
      filter.refType = refType;
    }

    if (refType && refId) {
      filter.refId = refId;
    }
    await TransactionService.exportTransactions(filter);
  };

  const updateFilter = (values) => {
    if (values.status) {
      filter.status = values.status;
    } else {
      delete filter.status;
    }

    if (values.transactionId) {
      filter.id = values.transactionId;
    } else {
      delete filter.id;
    }

    if (values.daterange?.length === 2) {
      const [from, to] = values.daterange;
      filter.createdFrom = moment(`${from.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD hh:mm:ss').utc().format();
      filter.createdTo = moment(`${to.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD hh:mm:ss').utc().format();
    } else {
      delete filter.createdFrom;
      delete filter.createdTo;
    }

    return { ...filter };
  };

  const search = (values) => {
    const newFilter = updateFilter(values);

    setFilter(newFilter);
    initData(newFilter);
  };

  const onChangeTable = (params) => {
    const obj = {
      ...filter,
      ...params,
    };
    setFilter(obj);
    initData(obj);
  };

  return (
    <div className="dashboard-transactions__list">
      <CardComponent
        icon={transactionsIcon}
        title="Transactions"
        extra={
          <Space size={[16]}>
            <RPButton onClick={handleOnClickFilter}>Filter</RPButton>
            <RPButton onClick={exportCSV}>Export CSV</RPButton>
          </Space>
        }
      >
        <div
          className={`dashboard-transactions__list__filter-container ${showFilter ? 'd-block' : 'd-none'} `}
        >
          <FilterComponent
            statuses={optsStatusTransaction}
            closeFilter={handleCloseFilter}
            search={search}
            searchFieldName='Transaction ID'
          />
        </div>
        <TableComponent
          loading={loading}
          className="table-transactions"
          data={data}
          columns={columns}
          scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
          onChangeTable={onChangeTable}
          totalPages={totalPages}
        />
      </CardComponent>
    </div>
  );
}

TransactionsTable.propTypes = {
  refType: PropTypes.string,
  refId: PropTypes.string
};

export default TransactionsTable;
