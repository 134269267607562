import { useState, useEffect } from 'react';
import { camelCase, startCase, toLower } from 'lodash';

export * as errorUtil from './errorUtil';
export * as loadingUtil from './loadingUtil';
export * as localStorageUtil from './localStorageUtil';
export * as validate from './validate';
export * as cardTypes from './cardTypes';
export * as formatter from './formatter';
export * as parse from './parse';
export * as messageNotifyUtil from './messageNotifyUtil';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};


export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setIsRun(false), deps);
};

export const titleizeString = string => startCase(toLower(String(string)));

export const filenameFromUrl = url => decodeURIComponent(url.split('/').pop().split('#')[0].split('?')[0]);

export const parameterizeString = string => camelCase(string).replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
