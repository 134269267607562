export const KYCStatus = [
  'StatusUndefined',
  'StatusNew',
  'StatusKYCSubmitted',
  'StatusRejected',
  'StatusActivated',
];

export const listStatuses = [
  ...KYCStatus,
  'NotSubmitKYC'
];

export const KYCStatusesMapping = {
  New: 1,
  Submitted: 2,
  Rejected: 3,
  Activated: 4
};

export const KYCStatusesMappingText = [
  '',
  'New',
  'Submitted',
  'Rejected',
  'Activated'
];

export const StateList = [
  {
    value: 'AN',
    text: 'Andaman And Nicobar',
  },
  {
    value: 'AP',
    text: 'Andhra Pradesh',
  },
  {
    value: 'AR',
    text: 'Arunachal Pradesh',
  },
  {
    value: 'AS',
    text: 'Assam',
  },
  {
    value: 'BI',
    text: 'Bihar',
  },
  {
    value: 'CH',
    text: 'Chandigarh (UT)',
  },
  {
    value: 'CT',
    text: 'Chattisgarh',
  },
  {
    value: 'DN',
    text: 'Dadra And Nagar Haveli',
  },
  {
    value: 'DD',
    text: 'Daman And Diu (UT)',
  },
  {
    value: 'DL',
    text: 'Delhi',
  },
  {
    value: 'GO',
    text: 'Goa',
  },
  {
    value: 'GJ',
    text: 'Gujarat',
  },
  {
    value: 'HA',
    text: 'Haryana',
  },
  {
    value: 'HP',
    text: 'Himachal Pradesh',
  },
  {
    value: 'JK',
    text: 'Jammu And Kashmir',
  },
  {
    value: 'JH',
    text: 'Jharkhand',
  },
  {
    value: 'KA',
    text: 'Karnataka',
  },
  {
    value: 'KE',
    text: 'Kerala',
  },
  {
    value: 'LD',
    text: 'Lakshadweep',
  },
  {
    value: 'MP',
    text: 'Madhya Pradesh',
  },
  {
    value: 'MH',
    text: 'Maharashtra',
  },
  {
    value: 'MA',
    text: 'Manipur',
  },
  {
    value: 'ME',
    text: 'Meghalaya',
  },
  {
    value: 'MI',
    text: 'Mizoram',
  },
  {
    value: 'NA',
    text: 'Nagaland',
  },
  {
    value: 'OR',
    text: 'Orissa',
  },
  {
    value: 'PO',
    text: 'Pondicherry(UT)',
  },
  {
    value: 'PB',
    text: 'Punjab',
  },
  {
    value: 'RJ',
    text: 'Rajasthan',
  },
  {
    value: 'SK',
    text: 'Sikkim',
  },
  {
    value: 'TG',
    text: 'Telangana',
  },
  {
    value: 'TN',
    text: 'Tamilnadu',
  },
  {
    value: 'TR',
    text: 'Tripura',
  },
  {
    value: 'UP',
    text: 'Uttar Pradesh',
  },
  {
    value: 'UT',
    text: 'Uttranchal',
  },
  {
    value: 'WB',
    text: 'West Bengal',
  },
];

export const BusinessTypesEnum = [
  {
    value: 1,
    text: 'Proprietorship',
  },
  {
    value: 2,
    text: 'Partnership',
  },
  {
    value: 3,
    text: 'Private Limited',
  },
  {
    value: 4,
    text: 'Public Limited',
  },
  {
    value: 5,
    text: 'LLP',
  },
  {
    value: 6,
    text: 'Trust',
  },
  {
    value: 7,
    text: 'Society',
  },
  {
    value: 8,
    text: 'NGO',
  },
  {
    value: 9,
    text: 'Not Yet Registered',
  },
];

export const BusinessCategoriesEnum = [
  {
    value: 1,
    text: 'Ecommerce',
  },
  {
    value: 2,
    text: 'Education',
  },
  {
    value: 3,
    text: 'Fashion and Lifestyle',
  },
  {
    value: 4,
    text: 'Food and Beverage',
  },
  {
    value: 5,
    text: 'Grocery',
  },
  {
    value: 6,
    text: 'IT and Software',
  },
  {
    value: 7,
    text: 'Healthcare',
  },
  {
    value: 8,
    text: 'Services',
  },
  {
    value: 9,
    text: 'Web designing/Development',
  },
  {
    value: 10,
    text: 'Accounting Services',
  },
  {
    value: 11,
    text: 'Ad/Coupons/Deals Services',
  },
  {
    value: 12,
    text: 'Automotive Repair Shops',
  },
  {
    value: 13,
    text: 'Cab Service',
  },
  {
    value: 14,
    text: 'Caterers',
  },
  {
    value: 15,
    text: 'Charity',
  },
  {
    value: 16,
    text: 'Computer Programming/Data Processing',
  },
  {
    value: 17,
    text: 'Consulting/PR Services',
  },
  {
    value: 18,
    text: 'Financial Services',
  },
  {
    value: 19,
    text: 'Gaming',
  },
  {
    value: 20,
    text: 'General Merchandise Stores',
  },
  {
    value: 21,
    text: 'Government Bodies',
  },
  {
    value: 22,
    text: 'Health and Beauty Spas',
  },
  {
    value: 23,
    text: 'Housing and Real Estate',
  },
  {
    value: 24,
    text: 'Logistics',
  },
  {
    value: 25,
    text: 'Media and Entertainment',
  },
  {
    value: 26,
    text: 'Not-For-Profit',
  },
  {
    value: 27,
    text: 'Others',
  },
  {
    value: 28,
    text: 'Platform as a Service',
  },
  {
    value: 29,
    text: 'Real Estate Agents/Rentals',
  },
  {
    value: 30,
    text: 'Software as a Service',
  },
  {
    value: 31,
    text: 'Service Centre',
  },
  {
    value: 32,
    text: 'Social',
  },
  {
    value: 33,
    text: 'Pre/Post Paid/Telecom Services',
  },
  {
    value: 34,
    text: 'Tours and Travel',
  },
  {
    value: 35,
    text: 'Transport',
  },
  {
    value: 36,
    text: 'Utilities-General',
  },
  {
    value: 37,
    text: 'Utilities–Electric, Gas, Water, Oil',
  },
];
