import axios from 'axios';
import { ENV, ROUTES, StorageKey } from '@/constants';
import { ApiAuth, ApiUser } from '@/constants/api';
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
} from '@/utils/localStorageUtil';
import { parse } from '@/utils';

const http = axios.create({
  baseURL: ENV.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getLocalAccessToken()}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

http.interceptors.response.use(
  (res) => res,
  async (error) => {
    console.clear();
    if (error.response?.status === 500) {
      window.location.href = ROUTES.SERVER_ERROR;
      return null;
    }
    const originalConfig = error.config;
    if (originalConfig.url === ApiAuth.refreshToken) {
      localStorage.removeItem(StorageKey.authUser);
      window.location.href = ROUTES.LOGIN_PATH;
    }
    if (originalConfig.url !== ApiAuth.signIn && error.response) {
      // Access Token was expired
      if (
        !originalConfig.retry &&
        (error.response.status === 401 || (error.response.status === 400 && originalConfig.url === ApiUser.generateOTP))
      ) {
        originalConfig.retry = true;
        try {
          const payload = parse.snakelize({ refreshToken: getLocalRefreshToken() });
          const rs = await http.post(ApiAuth.refreshToken, payload);

          const { accessToken } = parse.camelize(rs.data);

          updateLocalAccessToken(accessToken);

          return http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
);
export default http;
