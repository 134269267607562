import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Button,
} from 'antd';

import arrowLeftIcon from '@/assets/images/arrow-left.svg';
import arrowRightIcon from '@/assets/images/arrow-right.svg';

const { Option } = Select;

function TablePagination({
  current = 1,
  totalPages,
  pageSizeOptions = [10, 20, 50, 100],
  onNext,
  onPrevious,
  onSelectRow,
}) {
  return (
    <div className="table-pagination-component">
      <div className="row-per-page">
        <span>Rows per page</span>
        <Select
          defaultValue={pageSizeOptions[0]}
          className="select"
          onChange={onSelectRow}
        >
          {pageSizeOptions.map(pageSize => (
            <Option key={pageSize}>{pageSize}</Option>
          ))}
        </Select>
      </div>

      <ul className="pagination">
        <li className="prev">
          <Button
            type="link"
            onClick={onPrevious}
          >
            <img
              src={arrowLeftIcon}
              alt="arrow left icon"
              width={18}
              height={18}
            />
          </Button>
        </li>
        <li>
          <span className="current-page">{current}</span>
          <span>/</span>
          <span className="total-page">{totalPages}</span>
        </li>
        <li className="next">
          <Button
            type="link"
            onClick={onNext}
          >
            <img
              src={arrowRightIcon}
              alt="arrow right icon"
              width={18}
              height={18}
            />
          </Button>
        </li>
      </ul>
    </div>
  );
};

TablePagination.propTypes = {
  onNext: PropTypes.func,
  current: PropTypes.number,
  onPrevious: PropTypes.func,
  totalPages: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  onSelectRow: PropTypes.func,
};

export default TablePagination;
