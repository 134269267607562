import React, { useState } from 'react';
import {
  Row,
  Col,
} from 'antd';

import PageHeader from '@/components/PageHeader';
import CardComponent from '@/components/CardComponent';
import Account from '@/components/Dashboard/Account';
import PaymentInsights from '@/components/Dashboard/PaymentInsights';
import { DashboardData } from '@/components/Dashboard/data';
import paypageGreyIcon from '@/assets/images/sidebar/paypage-grey-icon.svg';
import paylinkGreyIcon from '@/assets/images/sidebar/paylink-grey-icon.svg';
import currentPaymentIcon from '@/assets/images/sidebar/current-payment-icon.svg';
import PaymentCard from '@/components/Dashboard/PaymentCard/index';
import CurrentPayment from '@/components/Dashboard/CurrentPayment';
import PaymentButton from '@/components/Dashboard/PaymentButton/index';

import './index.scss';
import { useBeforeRender } from '@/utils';
import { KYCStatus } from '@/constants/kyc';
import { getMerchantKyc } from '@/services/KYCService';
import { ROUTES } from '@/constants';
import TransactionsTable from '@/components/Transactions/table';

function DashboardPage() {
  const [pageHeaderKlass, setPageHeaderKlass] = useState('');
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState('');
  const [pageHeaderData, setPageHeaderData] = useState({});
  const initData = async () => {
    setLoading(true);
    const { success, data } = await getMerchantKyc();
    if (success) {
      let status = KYCStatus[data.status];
      const currentStep = data.step;
      if (status === 'StatusNew' && currentStep === 3) {
        status = 'NotSubmitKYC';
      }
      setAccountType(status);

      if (status === 'StatusKYCSubmitted') {
        setPageHeaderKlass('--kyc-submitted');
      }

      if (status === 'StatusActivated') {
        setPageHeaderKlass('--account-activated');
      }
      setPageHeaderData(DashboardData[status].pageHeader);
    }

    setLoading(false);
  };

  useBeforeRender(() => initData(), []);

  return (
    <div className="user-portal-dashboard">
      <PageHeader
        withImage
        loading={loading}
        {...pageHeaderData}
        className={pageHeaderKlass}
      />

      <div
        className="dashboard__container"
      >
        <Account
          loading={loading}
          type={accountType}
        />
        <PaymentButton
          loading={loading}
        />
        <Row
          gutter={[32]}
          className="dashboard__payment-button-and-link"
        >
          <Col span={12}>
            <CardComponent
              loading={loading}
              icon={paypageGreyIcon}
              title="Payment Pages"
            >
              <PaymentCard
                loading={loading}
                content="Receive Payments Effortlessly"
                desc="Get paid easily with a dedicated and customised payment page."
                labelBtn="Create Page"
                path={ROUTES.APP.PAYMENT_PAGES.NEW}
              />
            </CardComponent>
          </Col>
          <Col span={12}>
            <CardComponent
              loading={loading}
              icon={paylinkGreyIcon}
              title="Payment Links"
            >
              <PaymentCard
                loading={loading}
                content="Expand Payment Network"
                desc="Make payments easy and convenient for your customers with a payment link!"
                labelBtn="Create Link"
                path={ROUTES.APP.PAYMENT_LINKS.NEW}
              />
            </CardComponent>
          </Col>
        </Row>

        <CardComponent
          loading={loading}
          icon={currentPaymentIcon}
          title="Current Payment"
        >
          <CurrentPayment />
        </CardComponent>
        <PaymentInsights />
        <TransactionsTable />
      </div>
    </div>
  );
}

export default DashboardPage;
