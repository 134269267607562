/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Space,
  Button,
  Dropdown,
} from 'antd';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';

import { parameterizeString } from '@/utils';
import RPButton from '@/components/RPButton';
import MenuHeader from '@/components/Header/menu';
import ModalComponent from '@/components/ModalComponent';
import arrowDownBlue from '@/assets/images/header/arrow-down-blue.svg';
import arrowDownWhite from '@/assets/images/header/arrow-down-white.svg';
import notiIcon from '@/assets/images/header/noti-icon.svg';
import notiDarkIcon from '@/assets/images/header/noti-dark-icon.svg';
import liveModeIcon from '@/assets/images/mode/live-mode-icon.svg';
import testModeIcon from '@/assets/images/mode/test-mode-icon.svg';
import { RDCP_MODES, ROUTES } from '@/constants';
import { KYCStatusesMapping } from '@/constants/kyc';

import './index.scss';


const data = {
  white: {
    bgKlass: '--bg-white',
    arrowIcon: arrowDownBlue,
    notificationIcon: notiDarkIcon,
  },
  transparent: {
    bgKlass: '--bg-transparent',
    arrowIcon: arrowDownWhite,
    notificationIcon: notiIcon,
  }
};

// type: 'white' || 'transparent'
function RPHeader({
  user,
  onChangeMode,
  getCurrentKycStatus,
  type = 'transparent',
}) {
  const [visible, setVisible] = useState(false);
  const currentMode = user.workMode;
  const [currentModeObj, setCurrentModeObj] = useState(() => ({
    name: `${currentMode} Mode`,
    key: parameterizeString(`${currentMode}-mode`),
    iconUrl: currentMode === RDCP_MODES.LIVE_MODE ? liveModeIcon : testModeIcon,
  }));
  const {
    bgKlass,
    arrowIcon,
    // notificationIcon,
  } = data[type];
  const history = useHistory();
  const [isShowNotifyActivationRequired, setIsShowNotifyActivationRequired] = useState(false);
  const [isLoadingSwitchMode, setIsLoadingSwitchMode] = useState(false);
  const openPath = (path) => {
    setVisible(false);
    history.replace(path);
  };

  const canSwitchToLiveMode = async () => {
    const currentKycStatus = await getCurrentKycStatus();
    return currentKycStatus === KYCStatusesMapping.Activated;
  };

  const onClickChangeMode = async (e) => {
    if (currentModeObj.key === e.key) {
      return;
    }
    const isLiveMode = e.key === 'live-mode';
    const isTestMode = e.key === 'test-mode';
    let mode = '';
    let iconUrl = '';
    let name = '';
    // Switch to Live Mode
    if (isLiveMode) {
      const canSwitch = await canSwitchToLiveMode();
      if (!canSwitch) {
        setIsShowNotifyActivationRequired(true);
        return;
      }

      mode = RDCP_MODES.LIVE_MODE;
      iconUrl = liveModeIcon;
      name = `${RDCP_MODES.LIVE_MODE} Mode`;
    }
    // Switch to Test Mode
    if (isTestMode) {
      mode = RDCP_MODES.TEST_MODE;
      iconUrl = testModeIcon;
      name = `${RDCP_MODES.TEST_MODE} Mode`;
    }
    setIsLoadingSwitchMode(true);
    const res = await onChangeMode({ mode });
    if (res.success) {
      setCurrentModeObj({ name, iconUrl, key: e.key });
      setTimeout(() => {
        document.location.reload(true);
      }, 200);
    }
    setIsLoadingSwitchMode(false);
  };

  const switchModeMenu = () => (
    <Menu
      onClick={onClickChangeMode}
      selectedKeys={[currentModeObj.key]}
      items={[
        {
          label: 'Test Mode',
          key: 'test-mode',
          icon: <img src={testModeIcon} alt='test mode' width='16px' height='16px' />,
        },
        {
          label: 'Live Mode',
          key: 'live-mode',
          icon: <img src={liveModeIcon} alt='test mode' width='16px' height='16px' />,
        }
      ]}
    />
  );

  const handleOnClickFillActivationForm = () => {
    setIsShowNotifyActivationRequired(false);
    history.replace(ROUTES.APP.KYC_PATH);
  };

  return (
    <div
      className={`user-portal__header pb-0 ${bgKlass}`}
    >
      <header>
        <div className="header-menu">
          <div id='rp-change-mode'>
            <Dropdown
              overlayClassName='dropdown-custom'
              overlay={switchModeMenu()}
              trigger={['click']}
              getPopupContainer={() => document.getElementById('rp-change-mode')}
            >
              <Button
                loading={isLoadingSwitchMode}
                className="user-portal__header__button --custom"
                icon={<img src={currentModeObj.iconUrl} alt={currentModeObj.name} width='16px' height='16px' />}
              >
                <Space>
                  {currentModeObj.name}
                  <img
                    src={arrowIcon}
                    alt="arrow down blue"
                    height={10}
                    width={10}
                  />
                </Space>
              </Button>
            </Dropdown>
          </div>

          {/* <Button className="user-portal__header__button --custom --circle">
            <img
              src={notificationIcon}
              alt="icon"
              className="user-portal__header__icon"
            />
          </Button> */}
          <div id='rp-account-menu'>
            <Dropdown
              overlay={
                <MenuHeader openPath={openPath} />
              }
              trigger={['click']}
              getPopupContainer={() => document.getElementById('rp-account-menu')}
              overlayClassName='dropdown-custom --account-dropdown'
              visible={visible}
              onVisibleChange={setVisible}
              destroyPopupOnHide
            >
              <Button className="user-portal__header__button --custom --avatar --circle">
                <Avatar
                  round
                  size="36"
                  alt={user?.email}
                  name={user?.email}
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </header>
      <ModalComponent
        centered
        footer={null}
        getContainer={() => document.body}
        visible={isShowNotifyActivationRequired}
        onCancel={() => setIsShowNotifyActivationRequired(false)}
        title={<span className='fw-bold'>Activation Required</span>}
      >
        <div>You can only use RadicalPay in test mode until your account is activated.</div>
        <p>Please fill and submit the Activation Form to access live mode.</p>
        <RPButton
          center
          gradient
          onClick={handleOnClickFillActivationForm}
        >Fill Activation Form</RPButton>
      </ModalComponent>
    </div >
  );
}

RPHeader.propTypes = {
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getCurrentKycStatus: PropTypes.func,
  onChangeMode: PropTypes.func.isRequired,
};

export default RPHeader;
