import { capitalize } from 'lodash';
import * as formatter from './formatter';

export const parseTransactionsToDataTable = list => list?.map(data => ({
  key: data.id,
  id: data.id,
  paymentId: data.id,
  orderId: data.orderId,
  amount: formatter.formatCurrency(data.amount / 100),
  email: data.email || '--',
  contact: data.contactNumber ? `+${data.contactNumber}` : '--',
  createdOn: formatter.parseDateTime(data.createdAt),
  status: data.status,
}));

export const statusTransaction = [
  'PROCESSING',
  'CAPTURED',
  'FAILED',
  'PAYOUT',
  'PAID'
];

const paymentMethodTransaction = {
  upi: 'UPI',
  credit_debit: 'Payment Card',
  qr: 'QR',
  netbanking: 'Net Banking',
  wallet: 'Wallet'
};

export const optsStatusTransaction = [
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'CAPTURED', label: 'Captured' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PAYOUT', label: 'Payout' },
  { value: 'PAID', label: 'Paid' },
];

export const parseTransactionsDetail = data => {
  console.log(data);

  return {
    id: data.id,
    amount: data.amount / 100,
    status: capitalize(data.status),
    paymentMethod: paymentMethodTransaction[data.paymentMethod] || '--',
    createdAt: formatter.parseDateTime(data.createdAt),
    email: data.email || '--',
    contact: data.contactNumber ? `+${data.contactNumber}` : '--',
    totalFee: '0.00',
    radicalPayFee: '0.00',
    gstFee: '0.00',
    orderId: data.orderId,
    settlementDetails: '--'
  };
};
