import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { uniqueId } from 'lodash';

import closeModalIcon from '@/assets/images/modal/close-icon.svg';

import './index.scss';

export default function ModalComponent({
  children,
  visible,
  ...props
}) {
  const rpModalIdName = uniqueId('rp-modal-');

  useEffect(() => {
    document.body.classList.toggle('disable-scroll', visible);
  },[visible]);

  return (
    <div
      className='rp-modal-container'
      id={rpModalIdName}
    >
      <Modal
        destroyOnClose
        getContainer={() => document.getElementById(`${rpModalIdName}`)}
        closeIcon={
          <img src={closeModalIcon} alt='close icon' />
        }
        {...props}
        visible={visible}
      >
        {children}
      </Modal>
    </div>
  );
}

ModalComponent.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
  closeIcon: PropTypes.object,
};
