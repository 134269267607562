export const ApiAuth = {
  signIn: '/auth/signin',
  signUp: '/auth/signup',
  resendConfirm: '/auth/confirmation-email/resend',
  verify: '/auth/confirmation-email/verify',
  resetPassword: '/auth/reset-password',
  updatePassword: '/auth/reset-password-token',
  checkTokenUpdatePassword: '/auth/reset-password-token/verify',
  oauthLogin: '/auth/oauth-signup',
  refreshToken: '/auth/refresh-token',
};

export const ApiUser = {
  profile: '/user/profile',
  getAccountInformation: '/user/account',
  updateAccountPassword: '/user/account/change-password',
  updateAccountDisplayName: '/user/account/change-display-name',
  changeAccountSupportDetail: '/user/account/change-support-detail',
  updateAccountContactNumber: '/user/account/change-contact-number',
  generateOTP: '/user/otp',
  validateOTP: '/user/validate-otp',
  uploadAccountLogo: '/user/account/change-company-logo',
  changeMfa: '/user/account/change-mfa',
  updateCompanyDetail: '/user/account/change-company-detail',
  getCompanyDetail: '/user/account/company-detail',
  switchMode: '/user/switch-mode',
  changeConfigurations: '/user/account/change-configurations'
};

export const ApiKYC = {
  updateKyc: '/kyc',
  getMerchantKyc: '/kyc',
  submitKyc: 'kyc/submit',
  uploadKycDocument: '/kyc/upload',
};

export const ApiPaymentButton = {
  index: '/payment-buttons',
  new: '/payment-buttons',
  detail: '/payment-buttons/:id',
  update: '/payment-buttons/:id',
  publish: '/payment-buttons/:id/publish',
  unpublish: '/payment-buttons/:id/unpublish'
};

export const ApiPaymentPage = {
  index: '/payment-pages',
  detail: '/payment-pages/:id',
  update: '/payment-pages/:id',
  new: '/payment-pages',
  publish: '/payment-pages/:id/publish',
  unpublish: '/payment-pages/:id/unpublish'
};

export const ApiTransactions = {
  index: '/transactions',
  detail: '/transactions/:id',
  sendReceipt: '/transactions/:id/send-receipt',
  currentPayment: '/transactions/dashboard/current-payment',
  paymentInsight: '/transactions/dashboard/payment-insight',
  exportCSV: '/transactions/export',
};

export const ApiPaymentLink = {
  index: '/payment-links',
  create: '/payment-links',
  update: '/payment-links/:id',
  detail: '/payment-links/:id',
  cancel: '/payment-links/:id/cancel',
  send: '/payment-links/:id/send'
};

export const ApiSetting = {
  reminder: '/reminder-settings'
};
