import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'antd';

import TablePagination from './Pagination';

import './index.scss';

function TableComponent({
  data,
  columns,
  loading,
  noPagination,
  onChangeTable,
  totalPages = 16,
  ...props
}) {
  const [current, setCurrent] = useState(1);

  const onNext = () => {
    current < totalPages && setCurrent(current + 1);
  };

  const onPrevious = () => {
    current > 1 && setCurrent(current - 1);
  };

  useEffect(() => {
    if (totalPages < current) {
      setCurrent(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    onChangeTable && onChangeTable({
      page: current,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const onSelectRow = e => {
    onChangeTable && onChangeTable({
      limit: parseInt(e, 10),
    });
  };

  const renderTableFooter = () => (
    <TablePagination
      current={current}
      totalPages={totalPages}
      onNext={onNext}
      onPrevious={onPrevious}
      onSelectRow={onSelectRow}
    />
  );

  return (
    <div className="table-component">
      <Table
        {...props}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        footer={!noPagination && renderTableFooter}
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
      />
    </div>
  );
}

TableComponent.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  noPagination: PropTypes.bool,
  onChangeTable: PropTypes.func,
  totalPages: PropTypes.number
};

export default TableComponent;
