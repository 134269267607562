/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Menu, Space } from 'antd';
import Avatar from 'react-avatar';

import accountIcon from '@/assets/images/header/account-icon.svg';
// import faqIcon from '@/assets/images/header/faq-icon.svg';
import settingIcon from '@/assets/images/header/setting-icon.svg';
import { ROUTES } from '@/constants';
import { logout } from '@/store/auth/authSlice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getAuthUser } from '@/utils/localStorageUtil';
import RPButton from '../RPButton';

function MenuHeader({ openPath }) {
  const dispatch = useDispatch();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const user = getAuthUser();
  const { merchant } = user;
  const handleLogout = () => {
    setLoadingLogout(true);
    setTimeout(() => {
      dispatch(logout());
    }, 2000);
  };

  return (
    <Menu
      items={[
        {
          label: (
            <div className="user-portal__header__dropdown__merchant">
              {merchant?.companyLogoUrl ?
                <img
                  src={merchant?.companyLogoUrl}
                  alt="icon"
                  width="32px"
                  height="32px"
                />
                :
                <Avatar
                  size="32"
                  alt={merchant?.name}
                  name={merchant?.name}
                />
              }
              <div className="user-portal__header__dropdown__merchant__group">
                <p className="mb-0 fw-bold">{merchant?.name}</p>
                <p className="mb-0 user-portal__header__dropdown__merchant__text">MERCHANT ID: {merchant?.id}</p>
              </div>
            </div>
          ),
          key: '0',
          disabled: true,
        },
        {
          type: 'divider',
        },
        {
          label: (
            <div className="user-portal__header__dropdown__merchant">
              <Avatar
                round
                size="24"
                alt={user?.email}
                name={user?.email}
              />
              <p className="mb-0 fw-bold">{user?.email}</p>
            </div>
          ),
          key: '1',
          disabled: true,
        },
        {
          label: (
            <div
              className="user-portal__header__dropdown__item"
              onClick={() => openPath(ROUTES.APP.MY_ACCOUNT.PROFILE)}
            >
              <img
                src={accountIcon}
                alt="icon"
                width="32px"
                height="32px"
                className="user-portal__header__dropdown__item__icon"
              />
              <p className="mb-0">My Account</p>
            </div>
          ),
          key: '3',
        },
        // {
        //   label: (
        //     <div className="user-portal__header__dropdown__item">
        //       <img
        //         src={faqIcon}
        //         alt="icon"
        //         width="32px"
        //         height="32px"
        //         className="user-portal__header__dropdown__item__icon"
        //       />
        //       <p className="mb-0">FAQ</p>
        //     </div>
        //   ),
        //   key: '4',
        // },
        {
          label: (
            <div
              className="user-portal__header__dropdown__item"
              onClick={() => openPath(ROUTES.APP.SETTINGS.CONFIGURATIONS)}
            >
              <img
                src={settingIcon}
                alt="icon"
                width="32px"
                height="32px"
                className="user-portal__header__dropdown__item__icon"
              />
              <p className="mb-0">Settings</p>
            </div>
          ),
          key: '5',
        },
        {
          label: (
            <div className="user-portal__header__dropdown__item -opacity-1">
              <RPButton
                loading={loadingLogout}
                onClick={() => handleLogout()}
                className="w-100"
                danger
              >
                <Space>
                  Log Out
                </Space>
              </RPButton>
            </div>
          ),
          key: '6',
          disabled: true
        },
      ]}
      className="user-portal__dropdown-spacing"
    />
  );
};

MenuHeader.propTypes = {
  openPath: PropTypes.func,
};

export default MenuHeader;
